.profile {
  display: flex;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.808);
  width:80%
}

.username {
  margin-bottom: 10px;
  font-size: 30px;
  font-family:'Special Elite';
  margin-top: 30px;
}

.tous {
  margin-bottom: 10px;
  font-size: 30px;
  font-family:'Special Elite';
  color: rgba(255, 255, 255, 0.808);
  
}

.profile-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-pic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-right: 20px;
}

.profile-container {
  font-size: 15px;
  text-align: right;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.date {
  margin-top: 15px;
  display: flex;
  margin: 10px;
}



.my-button {
  margin-top: 20px;
  background-color:rgba(79, 7, 7, 0.806);;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: baseline;
  margin: 0 auto;
  justify-content: center;
  width: 200px;
  margin-top: 25px;

}

.my-button:after {
content: "";
background-color: rgba(255, 255, 255, 0.2);
position: absolute;
top: 50%;
left: 50%;
width: 5px;
height: 5px;
border-radius: 50%;
transform: translate(-50%, -50%);
opacity: 0;
}

.my-button:hover:after {
animation: ripple_401 1s ease-out;
}

@keyframes ripple_401 {
0% {
  width: 5px;
  height: 5px;
  opacity: 1;
}

100% {
  width: 200px;
  height: 200px;
  opacity: 0;
}
}


