.container2 {
  padding: 30px;
  border-radius: 5px;
  max-width: 650px;
  margin: auto;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  color: rgba(255, 255, 255, 0.708);
  margin: 10px;
  width: 90%;
  margin: 10px;
  margin: 0 auto; /* Ajouter cette ligne pour centrer horizontalement */
  text-align: center;
}

.container2 .article-img {
  width: 600px;
  height: auto;
  border-radius: 5px;
}

.container2 .article-title {
  font-size: 35px;
  font-family: 'Special Elite';
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
}

.container2 .article-description {
  display: none;
}

.container2 .markdown {
  margin-top: 25px;
  font-size: 17px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: justify;
}

.comments {
  margin-top: 165px;
  max-width: 650px;
  margin: 0 auto; /* Ajouter cette ligne pour centrer horizontalement */
  text-align: center;
}

.comment-user {
  font-size: 20px;
  font-family: 'Special Elite';
  font-weight: 600;
  margin-left: 20px;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 100px;
  
}

.comment-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.comment-content {
  display: flex;
  align-items:start;
}

.comment-date {
  font-size: 12px;
  margin-bottom: 50px;
}

.comment-text {
  margin-top: 40px;
}

 .tooltip {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: black;
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: .3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 12px;
  transition-duration: .3s;
}

.svgIcon path {
  fill: white;
}

.tooltip:hover {
  width: 50px;
  border-radius: 50px;
  transition-duration: .2s;
  background-color : rgba(175, 2, 2, 0.806);
  align-items: center;
}

.btn {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  position: relative;
}

.btn:hover > .icon {
  transform: scale(1.2);
  cursor: pointer;
  transition: .2s linear;
}

.btn:focus > .icon {
  fill: #fd1853;
  animation: grosseur .2s linear;
}

@keyframes grosseur {
  0% {
    width: 50px;
    height: 50px;
    fill: #fd1853;
  }
  100% {
    width: 30px;
    height: 30px;
    fill: #fd1853;
  }
}

.btn-like {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  position: relative;
  color: white;
}

.btn-like:hover > .icon {
  transform: scale(1.2);
  cursor: pointer;
  transition: .2s linear;
}

.btn-like:focus > .icon {
  fill: white;
  animation: grosseur .2s linear;
}

.icon {
  fill: white; /* Couleur du cœur non liké */
}


.btn-like.filled .icon path {
  fill: red;
}

.like {
  margin-bottom: 30px;
  margin-top: 30px;
}

