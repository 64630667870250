header{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-variant: small-caps;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px;
    border-radius: 5px;
    /* Utilise une largeur en pourcentage pour que la boîte modale soit responsive */
    max-width: 800px;
    /* Utilise une largeur maximale pour éviter que la boîte modale ne devienne trop grande */
    margin: auto;
    /* Centre la boîte modale horizontalement */
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    /* min-height: 140px; */
    flex-direction: column;
}

.main-title {
    /* margin-top: 30px; */
    font-size: 50px;
    font: 10px;
    color: rgb(255, 255, 255,0.8);
}
  

header .side-bar {
    list-style: none; 
    padding: 10px; 
    display: flex; 
    position: relative;
    top:15px;
    color: rgb(255, 255, 255,0.8);
}

header .side-bar li {
    margin-right: 40px;
    margin-left: 40px;
    font-size: 16px;
}
  
header .side-bar li:hover {
    cursor : pointer;
    color : rgba(175, 2, 2, 0.806);
    transition: color 0.2s;
}



header .admin {
  list-style: none; 
  padding: 3px; 
  display: flex; 
  position: relative;
  top:10px;
  color: rgb(255, 255, 255,0.8);
}

header .admin li {
  margin-right: 20px;
  margin-left: 20px;
  font-size: 17px;
}

header .admin li:hover {
  cursor : pointer;
  color : rgba(175, 2, 2, 0.806);
  transition: color 0.2s;
}




body {
    background-image: url(../assets/bg1.jpg);
    background-attachment: fixed;
    background-size: cover;
    padding: 25px;
  }

body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    z-index: -1;
  }
  
 
  
  .content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 30px;
    border-radius: 5px;
    /* Utilise une largeur en pourcentage pour que la boîte modale soit responsive */
    max-width: 800px;
    /* Utilise une largeur maximale pour éviter que la boîte modale ne devienne trop grande */
    margin: auto;
    /* Centre la boîte modale horizontalement */
    position: relative;
  
    /* Ajoutez cette propriété pour utiliser Flexbox */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  
  
.image-skull {
    position: fixed;
    top: 85%;
    left: 93%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

